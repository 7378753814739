export default {
  responseError: async ({ commit }, payload) => {
    if (payload.status == 401) {
      commit("snackbar", {
        snackbar: true,
        text: payload.mensaje,
      });
      commit("ExitApp");
    } else if (payload.status == 403) {
      commit("snackbar", {
        snackbar: true,
        text: payload.mensaje,
      });
      commit("ExitApp");
    } else {
      commit("snackbar", {
        snackbar: true,
        text: payload.mensaje,
      });
    }
  },
  TraeInfoArticuloXCB: async ({ state, dispatch }, payload) => {
    try {
      const response = await fetch(
        state.url +
          `/inventario/TraeInfoArticuloXCB?CodigoBarras=${payload}&db=${state.dbSelected}&Sucursal=${state.userInfo.sucursal}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": state.token,
          },
        }
      );
      const obj = await response.json();
      if (response.status == 200) {
        if (obj.length > 0) {
          return obj[0];
        } else {
          return null;
        }
      } else {
        let body = {
          status: response.status,
          mensaje:
            response.status == 401 || response.status == 403
              ? obj.mensaje
              : "Ocurrio un error, intente mas tarde",
        };
        dispatch("responseError", body);
      }
    } catch (error) {
      console.log(error);
    }
  },
  TraeInfoArticuloXItemno: async ({ state, dispatch }, payload) => {
    try {
      console.log(payload);
      const response = await fetch(
        state.url +
          `/inventario/TraeInfoArticuloXItemno?Itemno=${encodeURIComponent(payload)}&db=${state.dbSelected}&Sucursal=${state.userInfo.sucursal}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": state.token,
          },
        }
      );
      const obj = await response.json();
      if (response.status == 200) {
        if (obj.length > 0) {
          return obj[0];
        } else {
          return null;
        }
      } else {
        let body = {
          status: response.status,
          mensaje:
            response.status == 401 || response.status == 403
              ? obj.mensaje
              : "Ocurrio un error, intente mas tarde",
        };
        dispatch("responseError", body);
      }
    } catch (error) {
      console.log(error);
    }
  },
  ConsultaFolioConteoExiste: async ({ state, dispatch }, payload) => {
    try {
      const response = await fetch(
        state.url +
          `/inventario/ConsultaFolioConteoExiste?sucursal=${payload.sucursal}&folioConteo=${payload.folioConteo}&db=${state.dbSelected}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": state.token,
          },
        }
      );
      const obj = await response.json();
      if (response.status == 200) {
        if (obj.length > 0) {
          return obj[0].Comentario;
        } else {
          return null;
        }
      } else {
        let body = {
          status: response.status,
          mensaje:
            response.status == 401 || response.status == 403
              ? obj.mensaje
              : "Ocurrio un error, intente mas tarde",
        };
        dispatch("responseError", body);
      }
    } catch (error) {
      console.log(error);
    }
  },
  GuardaListaCache: async ({ state, dispatch, commit }, payload) => {
    try {
      const response = await fetch(
        state.url + `/inventario/GuardaListadoConteoCache`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": state.token,
          },
          body: JSON.stringify(payload),
        }
      );
      const obj = await response.json();
      if (response.status == 200) {
        if (obj.length > 0) {
          commit("snackbar", {
            snackbar: true,
            text: obj[0].mensaje,
          });
        }
      } else {
        let body = {
          status: response.status,
          mensaje:
            response.status == 401 || response.status == 403
              ? obj.mensaje
              : "Ocurrio un error, intente mas tarde",
        };
        dispatch("responseError", body);
      }
    } catch (error) {
      console.log(error);
    }
  },
  GuardarConteo: async ({ state, dispatch }, payload) => {
    try {
      const response = await fetch(
        state.url + `/inventario/guardar-conteo-v2`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": state.token,
          },
          body: JSON.stringify(payload),
        }
      );
      const obj = await response.json();
      if (response.status == 200) {
        if (obj.length > 0) {
          return obj[0];
        } else {
          return null;
        }
      } else {
        let body = {
          status: response.status,
          mensaje:
            response.status == 401 || response.status == 403
              ? obj.mensaje
              : "Ocurrio un error, intente mas tarde",
        };
        dispatch("responseError", body);
      }
    } catch (error) {
      console.log(error);
    }
  },
  GuardaRespaldoListaCache: async ({ state, dispatch, commit }, payload) => {
    try {
      const response = await fetch(
        state.url + `/inventario/guarda-respaldo-conteo`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": state.token,
          },
          body: JSON.stringify(payload),
        }
      );
      const obj = await response.json();
      if (response.status == 200) {
        if (obj.length > 0) {
          commit("snackbar", {
            snackbar: true,
            text: obj[0].mensaje,
          });
        }
      } else {
        let body = {
          status: response.status,
          mensaje:
            response.status == 401 || response.status == 403
              ? obj.mensaje
              : "Ocurrio un error, intente mas tarde",
        };
        dispatch("responseError", body);
      }
    } catch (error) {
      console.log(error);
    }
  },

  RegistraArticuloContado: async ({ state, dispatch, commit }, payload) => {
    try {
      const response = await fetch(
        state.url + `/inventario/registra-articulo-contado`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": state.token,
          },
          body: JSON.stringify(payload),
        }
      );
      const obj = await response.json();
      if (response.status == 200) {
        if (obj.length > 0) {
          console.log(obj[0].mensaje)
        }
      } else {
        let body = {
          status: response.status,
          mensaje:
            response.status == 401 || response.status == 403
              ? obj.mensaje
              : "Ocurrio un error, intente mas tarde",
        };
        dispatch("responseError", body);
      }
    } catch (error) {
      console.log(error);
    }
  },
};
